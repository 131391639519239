import {
  IconAlertTriangle,
  IconBolt,
  IconCategory,
  IconCircleCheck,
  IconCircleX,
  IconProgress,
  IconStatusChange,
} from '@tabler/icons-react';
import { useMemo } from 'react';
import { RISK_CATEGORIES, SEVERITIES, SEVERITY_LABEL_MAP } from '@/constants';
import { Status, useRiskFilters } from '@/store/risk-store';
import { CategoryEnum, SeverityEnum, Tag } from '@/api-client';

export interface FilterOptions {
  value: string;
  label: string;
  type?: string;
  color?: string;
  badge?: boolean;
  id?: string;
  tag?: string;
  emailHash?: string;
  meta?: Tag;
  icon?: React.ReactNode;
}

export interface FilterMeta {
  avatar?: boolean;
  label: string;
  id: string;
  options: FilterOptions[];
  icon?: React.ReactNode;
  default?: string[];
  selected: string[] | SeverityEnum[] | CategoryEnum[] | Status[] | undefined;
  onChange?: (value) => void;
  remove?: () => void;
}

export const useFilters = () => {
  const { setCategories, setSeverities, categories, severities, status, setStatus } =
    useRiskFilters();

  const filters: FilterMeta[] = useMemo(
    () => [
      {
        label: 'Status',
        id: 'status',
        options: [
          //{ value: 'all', label: 'All', icon: <IconStatusChange size="16" /> },
          {
            value: 'new',
            label: 'New',
            icon: <IconBolt size="16" />,
          },
          { value: 'in_progress', label: 'In Progress', icon: <IconProgress size="16" /> },
          { value: 'closed', label: 'Closed', icon: <IconCircleCheck size="16" /> },
          { value: 'rejected', label: 'Rejected', icon: <IconCircleX size="16" /> },
        ],
        selected: status,
        onChange: setStatus,
        icon: <IconStatusChange stroke={1.5} size="1rem" />,
      },
      {
        label: 'Severity',
        id: 'severity',
        options: SEVERITIES.map((severity) => ({
          value: severity,
          label: SEVERITY_LABEL_MAP[severity],
          badge: true,
        })),
        onChange: (e: SeverityEnum[]) => {
          setSeverities(e);
        },
        selected: severities,
        icon: <IconAlertTriangle stroke={1.5} size="1rem" />,
      },
      {
        label: 'Category',
        id: 'category',
        options: RISK_CATEGORIES.map((category) => ({
          value: category,
          label: category,
        })),
        selected: categories,
        onChange: (e: CategoryEnum[]) => {
          setCategories(e);
        },
        icon: <IconCategory stroke={1.5} size="1rem" />,
      },
    ],
    [severities, categories, setCategories, setSeverities, status, setStatus]
  );

  return { filters };
};

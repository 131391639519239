import { useSearchParams } from 'react-router-dom';

export type RiskStatus = 'new' | 'in_progress' | 'closed' | 'all';

export type StatusType = /*'all' |*/ 'closed' | 'in_progress' | 'new' | 'rejected' | undefined;

export type RiskDisplays = 'list' | 'kanban';

export const RiskTabs = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'New',
    value: 'new',
  },
  {
    label: 'In Progress',
    value: 'in_progress',
  },
  {
    label: 'Closed',
    value: 'closed',
  },
] as const;

export const KanbanRiskColumn = [
  {
    label: 'New',
    value: 'new',
  },
  {
    label: 'In Progress',
    value: 'in_progress',
  },
  {
    label: 'Closed',
    value: 'closed',
  },
  {
    label: 'Rejected',
    value: 'rejected',
  },
] as const;

export const useLayout = () => {
  const [searchParams] = useSearchParams();
  return (searchParams.get('layout') ?? 'list') as 'kanban' | 'list';
};
